import React from 'react';
import { Link } from 'react-router-dom';

import { useProjects } from '../contexts/ProjectsContext';
import MediaLoader from './MediaLoader';

const ImageSlider = ({ selectedTags, leftoutTags }) => {
	const { projects, setProjects, fetchProjects } = useProjects();

	return (
		<div className="flex flex-nowrap overflow-x-scroll gap-0 snap-x text-contents p-0">
			{projects
				.sort((a, b) => new Date(b.finished_date) - new Date(a.finished_date))
				.filter((project) => selectedTags.length === 0 || project.tags.some((tag) => selectedTags.includes(tag)))
				.filter((project) => !project.tags.some((tag) => leftoutTags.includes(tag)))
				.slice(0, 10)
				.map((project, index) => (
					<Link key={index} to={`/archive/${project._id}`} className='snap-start shrink-0 h-[250px] md:h-[300px] relative group overflow-hidden'>
						<MediaLoader
							src={`https://and-undox.com/api/uploads/images/${project.images[0]}`}
							thumb={true}
							type='image'
							alt={project.title}
							className="h-full"
							imgClassName={`
								h-full group-hover:brightness-75 transition-all ease-in-out shadow-sm
							`}
						/>
						<div
							className='
								group-hover:opacity-100 opacity-0 transition-all ease-in-out dark:bg-black dark:bg-opacity-30 bg-neutral-50 bg-opacity-30
								absolute top-0 grid font-extrabold justify-around w-full h-full content-center'
						>
							{project.title}
						</div>
					</Link>
				))}
		</div>
	);
};

export default ImageSlider;
