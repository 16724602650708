import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';

import MediaLoader from '../components/MediaLoader';
import PageBanner from '../components/PageBanner';
import { useProjects } from '../contexts/ProjectsContext';
import { useScrollDown } from '../contexts/ScrollDownContext';


const breakpointColumnsObj = {
	default: 6,
	1536: 5,
	1024: 4,
	768: 2
};

const Projects = () => {
	const { projects, setProjects, fetchProjects } = useProjects();
	const { scrollDown, scrollDownPercentage } = useScrollDown();

	const [andTags, setAndTags] = useState(JSON.parse(sessionStorage.getItem('andTags')) || []);
	const [selectedTags, setSelectedTags] = useState(JSON.parse(sessionStorage.getItem('selectedTags')) || []);
	const [tags, setTags] = useState([]);
	const [currentIdx, setCurrentIdx] = useState(0);

	const handleTagClick = (tag) => {
		if (tag === 'Photography' || tag === 'Videography' || tag === 'Visualizer') {
			if (andTags.includes(tag)) {
				setAndTags(andTags.filter((t) => t !== tag));
				sessionStorage.setItem('andTags', JSON.stringify(andTags.filter((t) => t !== tag)));
			} else {
				setAndTags([...andTags, tag]);
				sessionStorage.setItem('andTags', JSON.stringify([...andTags, tag]));
			}
		} else {
			if (selectedTags.includes(tag)) {
				setSelectedTags(selectedTags.filter((t) => t !== tag));
				sessionStorage.setItem('selectedTags', JSON.stringify(selectedTags.filter((t) => t !== tag)));
			} else {
				setSelectedTags([...selectedTags, tag]);
				sessionStorage.setItem('selectedTags', JSON.stringify([...selectedTags, tag]));
			}
		}
		window.scrollTo({ top: 0, behavior: 'smooth' });
		// console.log(sessionStorage.getItem('selectedTags'));
	};

	useEffect(() => {
		const tags = projects.reduce((acc, project) => {
			project.tags.forEach((tag) => {
				if (!acc.includes(tag)) {
					acc.push(tag);
				}
			});
			return acc;
		}, []);

		setTags(tags.sort());
		setSelectedTags(JSON.parse(sessionStorage.getItem('selectedTags')) || []);
	}, [projects]);

	useEffect(() => {
		if (currentIdx < projects.length) {
			const timer = setTimeout(() => {
				setCurrentIdx(currentIdx + 2);
			}, 50);

			return () => clearTimeout(timer);
		}
	}, [currentIdx, projects]);

	const filteredProjects = projects
		.filter((project) => !project.tags.includes('System'))
		.filter((project) => andTags.length === 0 || project.tags.some((tag) => andTags.includes(tag)))
		.filter((project) => selectedTags.length === 0 || project.tags.some((tag) => selectedTags.includes(tag)));

	const filteredTags = tags
		.filter((tag) => filteredProjects.some((project) => project.tags.includes(tag)));

	return (
		<motion.div
			className='grid grid-cols-1 gap-8 relative'
		// initial={{ opacity: 0 }}
		// animate={{ opacity: 1 }}
		// exit={{ opacity: 0 }}
		// transition={{ duration: 0.3 }}
		>
			<PageBanner image={`${process.env.PUBLIC_URL}/assets/system/sys_13.webp`} />

			<div
				className='w-full mx-auto p-2 md:p-4 flex justify-between gap-2 sticky top-[40px] md:top-0 z-20'
			>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: scrollDownPercentage / 5 }}
					transition={{ duration: 0 }}
					className={`
						absolute left-0 top-0 w-full h-full backdrop-blur bg-neutral-50 bg-opacity-30 dark:bg-black dark:bg-opacity-30
					`}
				>

				</motion.div>
				<div className='grid grid-cols-1 gap-[2px] md:gap-1 '>
					{tags.length === 0 && (
						<span className='tag animate-pulse'>Loading Tags</span>
					)}
					<div className='w-full flex flex-row gap-[2px] md:gap-1 flex-wrap text-xs'>
						{['Photography', 'Videography', 'Visualizer']
							.map((tag) => (
								<button
									key={tag}
									className={`tag-lg	${andTags.includes(tag) ? 'tag-selected' : ''}`}
									onClick={() => handleTagClick(tag)}
								>
									{tag}
								</button>
							))}
					</div>
					<div className='w-full flex flex-row gap-[2px] md:gap-1 flex-wrap text-xs'>
						{tags
							.filter((tag) => !['System', 'Photography', 'Videography', 'Visualizer'].includes(tag))
							.map((tag) => (
								<button
									key={tag}
									className={`tag	${selectedTags.includes(tag) ? 'tag-selected' : ''}`}
									onClick={() => handleTagClick(tag)}
								>
									{tag}
								</button>
							))}
					</div>
				</div>
				{/* <div className='flex flex-col h-full items-center justify-center gap-1 p-2'>
						<span className='text-lg font-bold'>{filteredProjects.length}</span>
					</div> */}
			</div>

			<motion.div
				className='grid grid-cols-1 gap-4 md:gap-8 w-full mx-auto px-2 md:px-4 z-10 -mt-4'
			// initial={{ opacity: 0, y: 20 }}
			// animate={{ opacity: 1, y: 0 }}
			>
				<Masonry
					breakpointCols={breakpointColumnsObj}
					className="my-masonry-grid flex gap-[2px] md:gap-1 min-h-[4000px] "
					columnClassName="my-masonry-grid_column flex flex-col gap-[2px] md:gap-1"
				>
					{filteredProjects
						.sort((a, b) => new Date(b.finished_date) - new Date(a.finished_date))
						.map((project, index) => (
							<motion.div
								key={project._id}
								className={`relative group overflow-hidden transition-all ease-in-out`}
							>
								<Link
									to={`/archive/${project._id}`}
									className='w-full h-full transition-all ease-in-out'
								>
									{project.images.length > 0 && (
										<MediaLoader
											src={`https://and-undox.com/api/uploads/images/${project.images[0]}`}
											type='image'
											alt={project.title}
											thumb={true}
											wait={index > currentIdx}
											className={`w-full group-hover:brightness-50 transition-all ease-in-out`}
										/>
									)}
									<div
										className='
											text-balance
											group-hover:opacity-100 opacity-0 transition-all ease-in-out dark:bg-black dark:bg-opacity-30 bg-neutral-50 bg-opacity-30
											absolute top-0 grid font-extrabold justify-around w-full h-full px-4 content-center text-center'
									>
										{project.title}
									</div>
								</Link>
							</motion.div>
						))}
				</Masonry>
			</motion.div>
		</motion.div>
	);
};

export default Projects;
