import axios from 'axios';
import { extractColors } from 'extract-colors';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLoading } from '../contexts/LoadingContext';

import MediaGrid from '../components/MediaGrid';
import PageBanner from '../components/PageBanner';
import PageHeader from '../components/PageHeader';


const logo =
	<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.28 80" fill="currentColor">
		<path d="M214.28,0s-52.33,34.49-107.14,34.49S0,0,0,0c0,0,48.28,49.97,3.19,79.26,0,0,47.51-17.52,103.96-17.52s103.96,17.52,103.96,17.52C166.02,49.97,214.3,0,214.3,0h-.02Z" />
	</svg>

const YouTubeEmbed = ({ link }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		setIsOpen(!isOpen);
	}

	return (
		<div className=''>
			<button className='tag flex gap-2 items-center hover:text-neutral-500 group' onClick={() => handleClick()}>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
					<path strokeLinecap="round" strokeLinejoin="round" d="m9 9 10.5-3m0 6.553v3.75a2.25 2.25 0 0 1-1.632 2.163l-1.32.377a1.803 1.803 0 1 1-.99-3.467l2.31-.66a2.25 2.25 0 0 0 1.632-2.163Zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 0 1-1.632 2.163l-1.32.377a1.803 1.803 0 0 1-.99-3.467l2.31-.66A2.25 2.25 0 0 0 9 15.553Z" />
				</svg>
				Listen to track
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 transition-transform ease-in-out ${isOpen && "rotate-90"}`}>
					<path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
				</svg>
			</button>

			<div className={`w-full ${isOpen ? 'mt-4 max-h-96' : 'h-0'} transition-all ease-in-out overflow-hidden`}>
				<iframe
					className='aspect-video w-full md:w-1/2'
					src={link}
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen>
				</iframe>
			</div>
		</div>
	);
}

const ProjectDetails = () => {
	const { projectId } = useParams();
	const { loading, startLoading, stopLoading } = useLoading();

	const [project, setProject] = useState(null);
	const [imageLoaded, setImageLoaded] = useState({});

	const [color, setColor] = useState('#7f807f');

	useEffect(() => {
		const options = {
			pixels: 640,
			distance: 0.2,
			colorValidator: (red, green, blue, alpha = 255) => alpha > 250,
			saturationDistance: 0,
			lightnessDistance: 0,
			hueDistance: 0,
		};

		const fetchProject = async () => {
			try {
				startLoading();
				const response = await axios.get(`https://and-undox.com/api/projects/${projectId}`);
				setProject(response.data);
				extractColors(`https://and-undox.com/api/uploads/images/${response.data.images[0]}`, options).then(colors => {
					setColor(colors[0].hex);
				});
			} catch (error) {
				console.error('Error fetching projects:', error);
			}
			finally {
				stopLoading();
			}
		};
		fetchProject();
	}, [projectId]);

	const handleImageLoad = (id) => {
		setImageLoaded((prev) => ({ ...prev, [id]: true }));
	};

	if (loading || !project) {
		return (
			<motion.div
				key={projectId}
				className='flex gap-1 justify-center items-center h-screen'
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5 }}
			>
				<span className='h-4 text-nowrap font-bold animate-pulse'>Loading Project Details...</span>
			</motion.div>
		);
	}

	return (
		<motion.div
			className='grid grid-cols-1 gap-8 md:gap-12'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
		>
			<PageBanner image={`https://and-undox.com/api/uploads/images/${project.images[0]}`} blur={true} />

			<motion.div
				className='grid grid-cols-1 lg:grid-cols-5 items-start gap-4 w-full mx-auto px-2 md:px-4 z-10'
			// initial={{ opacity: 0, y: 20 }}
			// animate={{ opacity: 1, y: 0 }}
			>
				<MediaGrid project={project} color={color} />

				<div className='grid grid-cols-1 gap-4 lg:col-span-2 lg:sticky lg:top-[256px]'>
					<PageHeader title={project.title} />

					<div className='grid grid-cols-1 gap-2'>
						<div className='w-full flex flex-wrap gap-[2px] md:gap-1 text-xs'>
							{project.tags
								.sort()
								.map((tag) => (
									<div key={tag} className={`tag-static`}>
										{tag}
									</div>
								))}
						</div>

						<div className='text-contents grid grid-cols-1 gap-4'>
							<div className='whitespace-pre-wrap'>
								{project.description}
							</div>

							<div className='flex flex-col'>
								{project.technicalities?.map((technicality, index) => (
									<span key={index} className='opacity-70'>{technicality}</span>
								))}
							</div>

							<div className='grid grid-cols-1 gap-2'>
								<div className='flex flex-col'>
									{project.collaborators.map((credit, index) => (
										<div key={index} className='flex gap-2'>
											<span className='opacity-70 truncate'>{credit.role}</span>
											{credit.link && (
												<a href={credit.link} className='hover:text-neutral-500 flex gap-1'>
													<span className='font-semibold text-nowrap'>{credit.name}</span>
													<span className=''>@{credit.link.split("/")[3]}</span>
												</a>
											)}
											{!credit.link && (
												<span className='font-bold'>{credit.name}</span>
											)}
										</div>
									))}
								</div>

								<hr className='opacity-30 my-4' />

								<div className={`opacity-70 text-xs flex gap-1 justify-start items-center`}>
									{!project.copyright && <span className={`*:h-4`} style={{ color }}>{logo}</span>}
									<span>&copy; {project.copyright ? project.copyright : "UNDoX"} All Rights Reserved,</span>
									<span>{project.finished_date.split('-')[0]}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		</motion.div>
	);
};

export default ProjectDetails;
