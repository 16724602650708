import { AnimatePresence, motion } from 'framer-motion';
import React, { lazy, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from 'react-router-dom';

import './App.css';

import Foot from './components/Foot';
import Nav from './components/Nav';
import NavV from './components/NavV';
import About from './pages/About';
import Article from './pages/Article';
import Home from './pages/Home';
import News from './pages/News';
import ProjectDetails from './pages/ProjectDetails';
import Projects from './pages/Projects';

import { useAuth } from './contexts/Auth.jsx';
import { LoadingProvider } from './contexts/LoadingContext.jsx';
import { usePeople } from './contexts/PeopleContext.jsx';
import { useProjects } from './contexts/ProjectsContext.jsx';
import { useScroll } from './contexts/ScrollContext.jsx';

import articles_db from './db/db_Notices.jsx';
import Links from './pages/Links.jsx';

const AdminPeople = lazy(() => import('./pages/AdminPeople'));
const Admin = lazy(() => import('./pages/Admin'));
const AdminLogin = lazy(() => import('./pages/AdminLogin'));


function App() {
	const { authToken, user, login, logout } = useAuth();

	const { saveScrollPosition, getScrollPosition } = useScroll();
	const location = useLocation();

	const [articles, setArticles] = useState([]);
	const { projects, setProjects, fetchProjects } = useProjects();
	const { people, setPeople, fetchPeople } = usePeople();

	const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
	const [darkMode, setDarkMode] = useState(prefersDarkMode);

	useEffect(() => {
		setArticles(articles_db);
		fetchPeople();
		fetchProjects();

		document.addEventListener('contextmenu', (event) => {
			event.preventDefault();
		});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		let scrollPosition = getScrollPosition(location.pathname);

		// console.log('scrollPosition', scrollPosition, window.scrollY);

		if (window.scrollY !== scrollPosition) {
			const wait = setTimeout(() => {
				window.scrollTo(0, scrollPosition);
				// console.log('scrollPosition after', window.scrollY);
			}, 500);
		}

		const handleScroll = () => {
			scrollPosition = window.scrollY;
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			saveScrollPosition(location.pathname, scrollPosition);
			window.removeEventListener('scroll', handleScroll);
		};
	}, [location.pathname]);

	return (
		<motion.div className={`App text-xs min-w-[320px] mx-auto leading-relaxed ${darkMode ? "dark" : ""}`}>
			<Helmet>
				{darkMode ?
					<meta name='theme-color' content='#000000' /> :
					<meta name='theme-color' content='#fafafa' />
				}
			</Helmet>

			<div
				className='text-black bg-neutral-50 dark:text-neutral-50 dark:bg-black relative'
			>
				<Nav />

				<NavV />

				<div className={`${location.pathname === "/" ? "" : "lg:ml-24"}`}>
					<LoadingProvider>
						<AnimatePresence mode='wait'>
							<Routes location={location}>
								<Route path='/' element={<Home />} />
								<Route path='/links' element={<Links />} />
								<Route path='/about' element={<About />} />
								<Route path='/archive' element={<Projects />} />
								<Route path='/archive/:projectId' element={<ProjectDetails />} />
								<Route path='/notice' element={<News articles={articles} />} />
								{articles.map((article) => (<Route key={article.id} path={`/notice/${article.id}`} element={<Article article={article} />} />))}

								<Route path='/admin' element={authToken ? <Admin /> : <AdminLogin />} />
								<Route path='/adminPeople' element={authToken ? <AdminPeople /> : <AdminLogin />} />

								{/* <Route path='/graph' element={<ProjectsGraph />} /> */}
								{/* <Route path='*' element={<Navigate to="/" />} /> */}
							</Routes>
						</AnimatePresence>
					</LoadingProvider>

					<Foot />
				</div>
			</div>
		</motion.div>
	);
}

export default App;
