import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useScrollDown } from '../contexts/ScrollDownContext';


const banner_images = [
	"squAt56Y.svg",
	"a4MxXfls.webp",
	"TyODLt1z.webp",
	"sHkjYMh6.webp",
	"fkRlmexi.webp",
	"l5UspsCp.webp",
	"VlEdROBC.webp",
	"nisGXmNv.webp",
	"1DWyTQYp.webp",
	"H0yObdHd.webp",
	"dNxc4epM.webp",
	"LYi9yb0x.webp",
	"CYrtOITa.webp",
	"ZovOyrlT.webp",
	"RF5hSSMY.svg",
	"b577p2L7.webp",
	"LlPIh240.webp",
	"2dCLngxl.webp"
]

const banner_videos = [
	"HuyNRWwX.mp4"
]

function HomeImage({ frame, bannersRand }) {
	const easeInQuint = cubicBezier(0.230, 1.000, 0.320, 1.000);
	const easeOutQuint = cubicBezier(0.755, 0.050, 0.855, 0.060);

	return (
		<AnimatePresence mode="wait">
			<motion.div
				key={frame}
				className={`h-full w-full relative`}
				initial={{ opacity: 0 }}
				animate={{
					opacity: 1,
					transition: {
						delay: 0.5,
						duration: 1,
						ease: easeInQuint
					}
				}}
				exit={{
					opacity: [1, 1, 1, 0],
					scaleX: [1, 1.3, 0],
					scaleY: [1, 0.001, 0.001],
					filter: ['brightness(1)', 'brightness(10)', 'brightness(55)'],
					transition: {
						duration: 0.5,
						times: [0, 0.6, 1],
						ease: easeOutQuint
					}
				}}
			>
				{frame === -1 && (
					<video
						className='h-full w-full object-cover'
						autoPlay
						muted
						loop
						playsInline
						src={`https://and-undox.com/api/uploads/videos/${banner_videos[Math.floor(Math.random() * banner_videos.length)]}`}
					/>
				)}
				<motion.div
					style={{
						backgroundImage: `
							url(https://and-undox.com/api/uploads/images/${bannersRand[frame]}),
							linear-gradient(90deg, rgba(0, 0, 0, 0.2) 5%, transparent)
						`,
						backgroundSize: 'cover, 2px 100%',
						backgroundPosition: 'center, center',
						backgroundBlendMode: 'multiply',
					}}
					className={`absolute top-0 left-0 h-full w-full`}
					animate={{
						y: [0, Math.random() * 0.5 + 0.5, 0, 0],
						scaleY: [1, Math.random() * 0.007 + 1, 1, 1],
						scaleX: [Math.random() * 0.005 + 1, 1, 1, Math.random() * 0.005 + 1],
						transition: {
							duration: 0.2,
							repeat: Infinity,
							repeatType: 'loop',
							ease: 'easeInOut'
						}
					}}
				></motion.div>
				<motion.div
					style={{
						backgroundImage: 'linear-gradient(black, rgba(255, 0, 0, 0.1) 30%, rgba(0, 255, 0, 0.2) 50%, rgba(0, 0, 255, 0.2) 70%, transparent)',
					}}
					className={`
						absolute top-0 left-0 h-full w-full
					`}
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: [1, Math.random() * 0.3 + 0.7, 1, 1],
						backgroundSize: ['100% 4px', '100% 3px'],
						transition: {
							duration: 0.3,
							repeat: Infinity,
							repeatType: 'loop',
							ease: 'easeInOut'
						}
					}}
				></motion.div>
				<div
					className='
						absolute bottom-0 w-full h-full opacity-65
						bg-gradient-to-t dark:from-black dark:via-transparent dark:to-transparent
						from-neutral-50 via-transparent to-transparent'
				></div>
				<div
					className='
						absolute bottom-0 w-full h-full opacity-65
						bg-gradient-to-b dark:from-black dark:via-10% dark:via-transparent dark:to-transparent
						from-neutral-50 via-10% via-transparent to-transparent'
				></div>
			</motion.div>
		</AnimatePresence>
	);
}

function HomeNav({ location }) {
	return (
		<Link
			to={`/${location}`}
			className={`group opacity-75 w-64 px-4 py-1 hover:opacity-100 hover:w-72 flex items-center justify-between`}
		>
			<svg
				className='transition-transform ease-in-out'
				xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M320-200v-560l440 280-440 280Z" />
			</svg>
			<div className='flex gap-2'>
				<span className='capitalize'>{location}</span>
			</div>
		</Link>
	);
}

const Home = () => {
	const { scrollDown, scrollDownPercentage } = useScrollDown();
	const [bannersRand, setBannersRand] = useState(banner_images);
	const [frame, setFrame] = useState(-1);

	useEffect(() => {
		const interval = setInterval(() => {
			setFrame((frame + 1) % bannersRand.length);
		}, 3000);

		return () => clearInterval(interval);
	}, [frame]);

	return (
		<motion.div
			className='grid grid-cols-1 relative'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<motion.div
				className='h-full ease-in-out w-full p-0 fixed overflow-hidden'
				style={{
					scale: `${1 + Math.max(0, scrollDownPercentage) / 500}`,
					opacity: `${1 - Math.max(0, scrollDownPercentage) / 500}`
				}}
			>
				{frame === -2 && (
					<div className='h-full w-full'></div>
				)}
				{frame > -2 && (
					<HomeImage frame={frame} bannersRand={bannersRand} />
				)}
			</motion.div>

			<div
				className='h-screen w-full z-10 relative overflow-hidden'
				onClick={() => window.scrollTo({ top: scrollDownPercentage < 50 ? window.innerHeight * 2 : 0, behavior: 'smooth' })}
			>
			</div>

			<div
				className={`
					transition-all ease-in-out *:transition-all *:ease-in-out
					text-2xl font-extrabold z-30 
					flex flex-col justify-center items-center w-full px-20 h-screen
				`}>
				<HomeNav location='about' />
				<HomeNav location='archive' />
				<HomeNav location='notice' />
				<div className='w-full h-32'></div>
			</div>

			<div className='
				w-full fixed bottom-6 z-50 pointer-events-none *:pointer-events-auto 
				flex justify-between items-center px-4'
			>
				<button
					className='h-4 group flex items-center'
					onClick={() => setFrame(-1)}
				>
					<div
						className='
						rounded-full h-3 w-3 shadow-lg shadow-black border-2 border-black	
						dark:border-neutral-50 dark:shadow-neutral-50 dark:text-neutral-50
						transition-all ease-in-out
						group-hover:bg-neutral-50'
					></div>
				</button>

				<button
					className={`
						flex justify-center mx-auto 
						transition-transform ease-in-out 
						hover:scale-125 items-center
						${scrollDownPercentage < 50 ? "" : "rotate-180"}`}
					onClick={() => window.scrollTo({ top: scrollDownPercentage < 50 ? window.innerHeight * 2 : 0, behavior: 'smooth' })}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
						<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
					</svg>
				</button>

				<button
					className='h-4 group flex items-center'
					onClick={() => setFrame((frame + 1) % bannersRand.length)}
				>
					<div
						className='
						rounded-full h-3 w-3 bg-red-500 shadow-lg shadow-red-500 text-red-500 
						transition-all ease-in-out animate-pulse 
						group-hover:brightness-110 group-hover:animate-none'
					></div>
				</button>
			</div>
		</motion.div>
	);
};

export default Home;
