import React from 'react';
import { Link, useLocation } from 'react-router-dom';


const logo =
	<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.3 110.34" fill="currentColor">
		<path d="M214.28,0s-52.33,34.49-107.14,34.49S0,0,0,0c0,0,48.28,49.97,3.19,79.26,0,0,47.51-17.52,103.96-17.52s103.96,17.52,103.96,17.52C166.02,49.97,214.3,0,214.3,0h-.02Z" />
	</svg>

const logo2 =
	<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.3 110.34" fill="currentColor">
		<path d="M249.3,55.17c-34.98-9.91-68.63-15.59-103.15-15.74-34.1.15-52.32,5.82-60.34,15.74C74.92,28.5,33.67,13.2,0,0c33.71,6.54,78.21,15.49,113.66,15.55,35.3-.06,55.46-9,71.37-15.55,2.24,13.2,2.6,28.5,64.26,55.17h0Z" />
		<path d="M249.3,55.17c-34.98,9.91-68.63,15.59-103.15,15.74-34.1-.15-52.32-5.82-60.34-15.74-10.89,26.67-52.14,41.97-85.81,55.17,33.71-6.54,78.21-15.49,113.66-15.55,35.3.06,55.46,9,71.37,15.55,2.24-13.2,2.6-28.5,64.26-55.17h0Z" />
	</svg>

const Foot = () => {
	const location = useLocation();

	return (
		<footer
			className={`
				${(location.pathname === "/" || location.pathname === '/graph') ? "hidden" : "mt-[50vh]"}
				relative text-xs py-8 z-30 overflow-hidden bg-neutral-50 dark:bg-black bg-opacity-20 dark:bg-opacity-20
				backdrop-blur-2xl dark:backdrop-blur-2xl
				justify-center items-center flex flex-col gap-12
			`}>
			<div className='
				mx-auto px-2 md:px-4 w-full font-semibold
				flex items-center justify-between
				*:transition-all *:ease-in-out'
			>
				<div className={`transition-all ease-in-out *:transition-all *:ease-in-out flex flex-col items-start`}>
					<Link to="/about" className={`flex gap-1 items-center hover:text-neutral-500 group`}>
						<div
							className={`
								transition-all ease-in-out
								rounded-full h-3 w-3 dark:border-neutral-50 border-yellow-300 border-2 
								group-hover:bg-neutral-500
								${location.pathname === '/about' ? "dark:bg-neutral-50 bg-black" : "group-hover:border-neutral-500"}`}>
						</div>
						<span>About</span>
					</Link>
					<Link to="/archive" className={`flex gap-1 items-center hover:text-neutral-500 group`}>
						<div
							className={`
								transition-all ease-in-out
								rounded-full h-3 w-3 dark:border-neutral-50 border-yellow-300 border-2 
								group-hover:bg-neutral-500 
								${location.pathname.includes('/archive') ? "dark:bg-neutral-50 bg-black" : "group-hover:border-neutral-500"}`}>
						</div>
						<span>Archive</span>
					</Link>
					{/* <Link to="/graph" className={`flex gap-1 items-center hover:text-neutral-500 group`}>
						<div
							className={`
								transition-all ease-in-out
								rounded-full h-3 w-3 dark:border-neutral-50 border-yellow-300 border-2 
								group-hover:bg-neutral-500 
								${location.pathname.includes('/graph') ? "dark:bg-neutral-50 bg-black" : "group-hover:border-neutral-500"}`}>
						</div>
						<span>Graph</span>
					</Link> */}
					<Link to="/notice" className={`flex gap-1 items-center hover:text-neutral-500 group`}>
						<div
							className={`
								transition-all ease-in-out
								rounded-full h-3 w-3 dark:border-neutral-50 border-yellow-300 border-2 
								group-hover:bg-neutral-500
								${location.pathname.includes('/notice') ? "dark:bg-neutral-50 bg-black" : "group-hover:border-neutral-500"}`}>
						</div>
						<span>Notice</span>
					</Link>
				</div>
				<div className={`transition-all ease-in-out *:transition-all *:ease-in-out flex flex-col items-end`}>
					{/* <Link to="/" className={`flex gap-1 items-center hover:text-neutral-500 group`}>
						<span>UNDoX</span>
						<div
							className={`
								transition-all ease-in-out
								rounded-full h-3 w-3 dark:border-neutral-50 border-yellow-300 border-2 
								group-hover:bg-neutral-500
								${location.pathname === '/' ? "dark:bg-neutral-50 bg-black" : "group-hover:border-neutral-500"}`}>
						</div>
					</Link> */}
					<Link
						className='hover:text-neutral-500 flex gap-1 items-center group'
						to='https://www.instagram.com/and_unorthodox'
						target='_blank'
					>
						<span
							className='font-semibold overflow-hidden text-nowrap
								transition-all ease-in-out w-0 group-hover:w-[102px]'
						>
							@and_unorthodox
						</span>
						Instagram
						<div
							className={`
								transition-all ease-in-out
								rounded-full h-3 w-3 border-pink-500 border-2 
								group-hover:bg-pink-500`}>
						</div>
					</Link>
					<Link
						className='hover:text-neutral-500 flex gap-1 items-center group'
						to='https://www.youtube.com/channel/UCnT1EgLiLk9ePeFKCINx6QQ'
						target='_blank'
					>
						<span
							className='font-semibold overflow-hidden text-nowrap
								transition-all ease-in-out w-0 group-hover:w-[40px]'
						>
							UNDoX
						</span>
						YouTube
						<div
							className={`
								transition-all ease-in-out
								rounded-full h-3 w-3 border-rose-800 border-2 
								group-hover:bg-rose-800`}>
						</div>
					</Link>
					<Link
						className='hover:text-neutral-500 flex gap-1 items-center group'
						to='mailto:studio@and-undox.com'
					>
						<span
							className='font-semibold overflow-hidden text-nowrap
								transition-all ease-in-out w-0 group-hover:w-[134px]'
						>
							studio@and-undox.com
						</span>
						Email
						<div
							className={`
								transition-all ease-in-out
								rounded-full h-3 w-3 border-blue-600 border-2 
								group-hover:bg-blue-600`}>
						</div>
					</Link>
				</div>
			</div>
			<div className='w-full px-2 md:px-4 text-neutral-500 flex flex-col gap-3 md:flex-row md:justify-between items-center md:items-end'>
				<span className='*:h-8'>{logo2}</span>
				<span>
					© 2022-{new Date().getFullYear()} UNDoX. All rights reserved
				</span>
			</div>
		</footer>
	);
};

export default Foot;