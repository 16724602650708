import { cubicBezier, motion } from 'framer-motion';
import React, { useEffect, useState } from "react";
import { useScrollDown } from '../contexts/ScrollDownContext';


const PageBanner = ({ image, fullScreen }) => {
	const { scrollDown, scrollDownPercentage } = useScrollDown();
	const [imageLoaded, setImageLoaded] = useState(false);
	const easeInQuint = cubicBezier(0.230, 1.000, 0.320, 1.000);


	useEffect(() => {
		const img = new Image();
		img.src = image;
		img.onload = () => {
			setImageLoaded(true);
		};
	}, [image]);

	return (
		<motion.div
			className={`${fullScreen ? "h-0" : "h-[30vh]"} z-10 relative overflow-hidden`}
		>
			<motion.div className='h-full w-full p-0 fixed overflow-hidden ease-in-out'>
				<motion.div
					className={`h-full w-full relative`}
					initial={{ opacity: 0 }}
					animate={imageLoaded ? { opacity: 1 } : { opacity: 0 }}
					transition={{ delay: 0.5, duration: 0.3, ease: easeInQuint }}
				>
					<motion.div
						key={image}
						style={{
							scale: `${1 + Math.max(0, scrollDownPercentage) / 800}`,
							opacity: `${1 - Math.max(0, scrollDownPercentage) / 300}`,
							backgroundImage: `
										url(${image}),
										linear-gradient(90deg, rgba(0, 0, 0, 0.2) 5%, transparent)
									`,
							backgroundSize: 'cover, 2px 100%',
							backgroundPosition: 'center, center',
							backgroundBlendMode: 'multiply',
						}}
						className={`absolute top-0 left-0 h-full w-full`}
						initial={{ opacity: 0 }}
						animate={{
							opacity: [1, 1, 1, 1],
							y: [0, Math.random() * 0.5 + 0.5, 0, 0],
							scaleY: [1, Math.random() * 0.001 + 1, 1, 1],
							scaleX: [Math.random() * 0.001 + 1, 1, 1, Math.random() * 0.001 + 1],
							transition: {
								duration: 0.2,
								repeat: Infinity,
								repeatType: 'loop',
								ease: 'easeInOut'
							}
						}}
					></motion.div>
					<motion.div
						style={{
							backgroundImage: 'linear-gradient(black, rgba(255, 0, 0, 0.1) 30%, rgba(0, 255, 0, 0.2) 50%, rgba(0, 0, 255, 0.2) 70%, transparent)',
							backgroundSize: '100% 4px',
						}}
						className={`absolute top-0 left-0 h-full w-full`}
						animate={{
							opacity: [1, Math.random() * 0.1 + 0.9, 1, 1],
							// backgroundSize: ['100% 4px', '100% 3px'],
							transition: {
								duration: 0.3,
								repeat: Infinity,
								repeatType: 'loop',
								ease: 'easeInOut'
							}
						}}
					></motion.div>
					<div
						className='
							absolute bottom-0 w-full h-full
							bg-gradient-to-t dark:from-black dark:via-transparent dark:to-transparent
							from-neutral-50 via-transparent to-transparent'
					></div>
					<div
						className='
							absolute bottom-0 w-full h-full
							bg-gradient-to-b dark:from-black dark:via-10% dark:via-transparent dark:to-transparent
							from-neutral-50 via-10% via-transparent to-transparent'
					></div>
				</motion.div>
			</motion.div>
		</motion.div>
	);
}

export default PageBanner;
