import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

import App from './App';
import { AuthProvider } from './contexts/Auth.jsx';
import { PeopleProvider } from './contexts/PeopleContext';
import { ProjectsProvider } from './contexts/ProjectsContext';
import { ScrollProvider } from './contexts/ScrollContext.jsx';
import { ScrollDownProvider } from './contexts/ScrollDownContext.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<AuthProvider>
			<BrowserRouter>
				<ScrollDownProvider>
					<ScrollProvider>
						<PeopleProvider>
							<ProjectsProvider>
								<App />
							</ProjectsProvider>
						</PeopleProvider>
					</ScrollProvider>
				</ScrollDownProvider>
			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>
);
