import React, { createContext, useContext, useEffect, useState } from 'react';

const ScrollDownContext = createContext();

export const useScrollDown = () => useContext(ScrollDownContext);

export const ScrollDownProvider = ({ children }) => {
	const [scrollDown, setScrollDown] = useState(0);
	const [scrollDownPercentage, setScrollDownPercentage] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			setScrollDown(window.scrollY);
			setScrollDownPercentage((window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<ScrollDownContext.Provider value={{ scrollDown, scrollDownPercentage }}>
			{children}
		</ScrollDownContext.Provider>
	);
};