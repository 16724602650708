import { Html, OrbitControls } from '@react-three/drei';
import { Canvas, useLoader, useThree } from '@react-three/fiber';
import React, { Suspense, useEffect, useRef } from 'react';
import Masonry from 'react-masonry-css';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import LinkMetaPreview from './LinkPreivew';

import MediaLoader from './MediaLoader';


function STLModel({ url, color = "silver" }) {
	const { camera } = useThree();
	const meshRef = useRef();
	const geometry = useLoader(STLLoader, url);

	useEffect(() => {
		camera.position.set(0, 0, 100);
	}, [camera]);

	return (
		<mesh
			ref={meshRef}
			geometry={geometry}
			scale={0.5}
			rotation={[Math.PI / 1.7, Math.PI, 0]}
			position={[0, 0, 0]}
		>
			<meshStandardMaterial color={color} metalness={0.8} roughness={0.5} />
		</mesh>
	);
}

const breakpointColumnsObj = {
	default: 2,
	768: 2,
	640: 1
};

const MediaGrid = ({ project, color }) => {
	return (
		<div key="media-grid" className='grid grid-cols-1 gap-1 lg:col-span-3'>
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className="my-masonry-grid flex gap-1"
				columnClassName="my-masonry-grid_column flex flex-col gap-1"
			>
				{[
					...project.images.map((image, index) => ({ image, type: 'image', index })),
					...project.videos
						.filter(video => project.tags.includes('Visualizer'))
						.map((video, index) => ({ video, type: 'video', index })),
					...project.links
						.filter(link => project.tags.includes('Visualizer'))
						.map((link, index) => ({ link, type: 'youtube', index }))
				].map((media, index) => (
					<>
						{media.type === 'image' ? (
							<MediaLoader
								key={index}
								src={`https://and-undox.com/api/uploads/images/${media.image}`}
								type='image'
								alt={project.title}
								className={`w-full transition-all ease-in-out`}
							/>
						) : (media.type === 'video' ? (
							<MediaLoader
								key={index}
								src={`https://and-undox.com/api/uploads/videos/${media.video}`}
								type='video'
								alt={project.title}
								className={`w-full transition-all ease-in-out`}
							/>
						) : <MediaLoader
							key={index}
							src={media.link}
							type='youtube-short'
							alt={project.title}
							className={`w-full transition-all ease-in-out`}
						/>
						)}
					</>
				))}
			</Masonry>

			{/* <div className="flex flex-nowrap overflow-x-scroll gap-0 snap-x text-contents p-0">
				{project.images.map((image, index) => (
					<div key={index} className='snap-start shrink-0 h-[500px] md:h-[700px] relative group overflow-hidden'>
						<MediaLoader
							src={`https://and-undox.com/api/uploads/images/${image}`}
							thumb={true}
							type='image'
							alt={project.title}
							className="h-full"
							imgClassName={`
								h-full group-hover:brightness-75 transition-all ease-in-out shadow-sm
							`}
						/>
					</div>
				))}
			</div> */}

			{project.tags.includes('Photography') && project.links.length > 0 && (
				project.links.map((link, index) => (
					<div key={index} className='w-full'>
						<LinkMetaPreview url={link} />
					</div>
				))
			)}

			{project.tags.includes('Videography') && (project.videos.map((video, index) => (
				<div key={index} className='w-full text-contents p-0'>
					<MediaLoader
						src={`https://and-undox.com/api/uploads/videos/${video}`}
						type='video'
						alt={project.title}
						className='w-full h-full'
					/>
				</div>
			)))}

			{project.tags.includes('Videography') && project.links.length > 0 && (
				project.links.map((link, index) => (
					<div key={index} className='w-full text-contents p-0'>
						<MediaLoader
							src={link}
							type='youtube'
							alt={project.title}
							className='w-full h-full'
						/>
					</div>
				))
			)}

			{project.stls.length > 0 && (project.stls.map((stlmodel, index) => (
				<div key={index} className='w-full text-contents aspect-square md:aspect-[2/1]'>
					<Canvas className='relative'>
						<ambientLight intensity={Math.PI / 2} />
						<directionalLight intensity={1} position={[0, 10, 10]} />
						<directionalLight intensity={1} position={[10, 10, 10]} />
						<directionalLight intensity={1} position={[-10, 10, 10]} />
						<directionalLight intensity={1} position={[0, -10, 10]} />
						<Suspense fallback={
							<Html center>
								<div className='flex gap-1 w-48 justify-center items-center'>
									<span className='h-6 text-nowrap font-bold animate-pulse'>Loading 3D Model...</span>
								</div>
							</Html>
						}>
							<STLModel url={`https://and-undox.com/api/uploads/stls/${stlmodel}`} color={color} />
						</Suspense>
						<OrbitControls minDistance={25} maxDistance={150} />
					</Canvas>
				</div>
			)))}
		</div >
	);
}

export default MediaGrid;