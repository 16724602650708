import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useScrollDown } from '../contexts/ScrollDownContext';


const logo =
	<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.3 110.34" fill="currentColor">
		<path d="M214.28,0s-52.33,34.49-107.14,34.49S0,0,0,0c0,0,48.28,49.97,3.19,79.26,0,0,47.51-17.52,103.96-17.52s103.96,17.52,103.96,17.52C166.02,49.97,214.3,0,214.3,0h-.02Z" />
	</svg>

const logo2 =
	<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.3 110.34" fill="currentColor">
		<path d="M249.3,55.17c-34.98-9.91-68.63-15.59-103.15-15.74-34.1.15-52.32,5.82-60.34,15.74C74.92,28.5,33.67,13.2,0,0c33.71,6.54,78.21,15.49,113.66,15.55,35.3-.06,55.46-9,71.37-15.55,2.24,13.2,2.6,28.5,64.26,55.17h0Z" />
		<path d="M249.3,55.17c-34.98,9.91-68.63,15.59-103.15,15.74-34.1-.15-52.32-5.82-60.34-15.74-10.89,26.67-52.14,41.97-85.81,55.17,33.71-6.54,78.21-15.49,113.66-15.55,35.3.06,55.46,9,71.37,15.55,2.24-13.2,2.6-28.5,64.26-55.17h0Z" />
	</svg>

const Nav = ({ }) => {
	const { scrollDown, scrollDownPercentage } = useScrollDown();
	const location = useLocation();

	return (
		<motion.nav
			className={`mx-auto z-50 transition-all fixed top-0 w-full`}
		>
			<div className='w-full h-full relative'>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: location.pathname === "/" ? 0 : scrollDownPercentage / 4 }}
					transition={{ duration: 0 }}
					className={`
						w-full h-full absolute backdrop-blur-2xl bg-opacity-60 scale-110
					`}
				>
				</motion.div>
				<div className="overflow-hidden relative w-full mx-auto">
					<AnimatePresence mode='wait'>
						{location.pathname === "/" && (
							<motion.div
								key="undox"
								className={`flex items-center py-3 px-4 w-full justify-center`}
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -20 }}
							>
								<Link
									id="nav-logo" to='/'
									className="
										group font-black flex hover:text-neutral-500 transition-all ease-in-out text-base
										*:h-4 *:md:h-6"
								>
									{logo2}
								</Link>
							</motion.div>
						)}
						{location.pathname !== "/" && (
							<motion.div
								key='navy'
								className={`flex items-center py-3 px-2 md:px-4 justify-center lg:hidden`}
								initial={{ opacity: 0, width: '110%' }}
								animate={{ opacity: 1, width: '100%' }}
								exit={{ opacity: 0, width: '110%' }}
							>
								<Link
									id="nav-logo" to='/'
									className="
										group font-black flex hover:text-neutral-500 transition-all ease-in-out text-base
										*:h-4 *:md:h-6"
								>
									{logo2}
								</Link>
								<div
									className={`transition-all ease-in-out *:transition-all *:ease-in-out flex text-right text-xs font-bold gap-2 ml-auto`}>
									<Link to="/about" className={`${location.pathname === '/about' ? "" : "opacity-50 hover:opacity-100"} z-50`}>About</Link>
									<Link to="/archive" className={`${location.pathname.includes('/archive') ? "" : "opacity-50 hover:opacity-100"} z-50`}>Archive</Link>
									<Link to="/notice" className={`${location.pathname.includes('/notice') ? "" : "opacity-50 hover:opacity-100"} z-50`}>Notice</Link>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		</motion.nav>
	);
};

export default Nav;
