import axios from 'axios';
import React, { useEffect, useState } from 'react';

const LinkMetaPreview = ({ url }) => {
	const [metaData, setMetaData] = useState({});

	useEffect(() => {
		const fetchMetaData = async () => {
			try {
				const response = await axios.get(`/api/linkPreview`, { params: { url } });
				setMetaData(response.data);
			} catch (error) {
				console.error('Error fetching link metadata:', error);
			}
		};

		if (url) {
			fetchMetaData();
		}
	}, [url]);

	return (
		<a
			href={url}
			target='_blank'
			rel='noreferrer'
			className="flex flex-row gap-2 items-center justify-between text-contents p-0 h-16 group hover:text-neutral-500"
		>
			{metaData.image && <img src={metaData.image} alt="Preview" className="object-cover h-16 w-20 group-hover:brightness-50" />}
			<div className="flex flex-col gap-1 text-xs overflow-hidden p-2">
				<p className='font-semibold truncate'>{metaData.title}</p>
				<p className='truncate opacity-50'>{metaData.description}</p>
			</div>
			<div className='flex items-center justify-center w-8 h-8 p-2 group-hover:rotate-180 transition-all ease-in-out'>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
					<path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
				</svg>
			</div>
		</a>
	);
};

export default LinkMetaPreview;