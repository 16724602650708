import { Html } from '@react-three/drei';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { usePeople } from '../contexts/PeopleContext';
import { useScrollDown } from '../contexts/ScrollDownContext';

import ContactCard from '../components/ContactCard';
import PageBanner from '../components/PageBanner';


const logo =
	<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.28 80" fill="currentColor">
		<path d="M214.28,0s-52.33,34.49-107.14,34.49S0,0,0,0c0,0,48.28,49.97,3.19,79.26,0,0,47.51-17.52,103.96-17.52s103.96,17.52,103.96,17.52C166.02,49.97,214.3,0,214.3,0h-.02Z" />
	</svg>

const Model = ({ url }) => {
	const { scrollDown, scrollDownPercentage } = useScrollDown();
	const gltf = useLoader(GLTFLoader, url);
	const meshRef = useRef();
	const cameraRef = useRef();

	useFrame(({ camera }) => {
		if (meshRef.current) {
			meshRef.current.rotation.y += 0.007; // Adjust the rotation speed as needed
			meshRef.current.rotation.z = scrollDownPercentage / 10;
		}
		if (cameraRef.current) {
			camera.position.set(0, 0, 3.7); // Set the camera position as needed
			camera.lookAt(meshRef.current.position);

			// Adjust the camera's field of view (fov) to change the focal length
			camera.fov = 70; // Example: set the fov to 35 degrees
			camera.updateProjectionMatrix(); // Update the projection matrix after changing the fov
		}
	});

	return (
		<>
			<mesh ref={meshRef} scale={[1, 1, 1]}>
				<primitive object={gltf.scene} />
			</mesh>
			<perspectiveCamera ref={cameraRef} />
		</>
	);
};

const About = () => {
	const [showCanvas, setShowCanvas] = useState(false);
	const { people, setPeople, fetchPeople } = usePeople();

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCanvas(true);
		}, 2000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<motion.div
			className='grid grid-cols-1 gap-8 md:gap-12'
		// initial={{ opacity: 0 }}
		// animate={{ opacity: 1 }}
		// exit={{ opacity: 0 }}
		// transition={{ duration: 0.3 }}
		>
			<PageBanner image={`${process.env.PUBLIC_URL}/assets/system/sys_10.webp`} />

			<motion.div
				className='grid grid-cols-1 lg:grid-cols-2 gap-4 mx-auto w-full px-2 md:px-4 z-10 items-start'
			// initial={{ y: 20 }}
			// animate={{ y: 0 }}
			>
				<div className="sticky top-12 z-50 pointer-events-none">
					<AnimatePresence mode='wait'>
						{showCanvas && (
							<Canvas 
								key="canvas" 
								className='relative aspect-[2/1]'
							>
								<directionalLight intensity={1} position={[0, 0, 10]} />
								<directionalLight intensity={1} position={[0, 10, 0]} />
								<directionalLight intensity={0.1} position={[10, 0, 0]} />
								<directionalLight intensity={1} position={[0, 0, -10]} />
								<directionalLight intensity={0.1} position={[0, -10, 0]} />
								<directionalLight intensity={1} position={[-10, 0, 0]} />
								<Suspense fallback={
									<Html center>
										<div className='flex gap-1 justify-center items-center'>
											<span className='h-6 text-nowrap font-bold animate-pulse'>Loading 3D Model...</span>
										</div>
									</Html>
								}>
									<Model url={`${process.env.PUBLIC_URL}/assets/logos/logo1_3D.glb`} />
								</Suspense>
								{/* <OrbitControls minDistance={5} maxDistance={5} /> */}
							</Canvas>
						)}
						{!showCanvas && (
							<motion.div
								key="loader"
								className="
									relative inset-0 flex p-4 aspect-[2/1] animate-pulse
									justify-center items-center text-neutral-50 *:h-16
								"
								exit={{ opacity: 0, scale: 2 }}
							>
								{logo}
							</motion.div>
						)}
					</AnimatePresence>
				</div>

				<div className='grid grid-cols-1 gap-8'>
					<div className='text-left text-contents grid gap-2'>
						<div>
							안녕하세요, UNDoX입니다.
							<br />
							<br />
							저희는 기술적 전문성과 예술적 비전을 효과적으로 조화시키는 다각적인 크리에이티브 스튜디오입니다. 사진, 비디오 촬영, 컴퓨터 비전을 통한 시각적 스토리텔링을 전문으로 하며, 감동적이고 영감을 주는 콘텐츠를 제작합니다.
							<br />
							<br />
							조명, 카메라 기술, 후반 제작에 대한 깊은 이해를 바탕으로 창의적인 한계를 넘어선 전문적인 결과물을 제공합니다. 색상, 구도, 스타일에 대한 세심한 접근 방식을 통해 각 프로젝트가 고객의 고유한 브랜드와 비전에 맞도록 조정됩니다.
							<br />
							<br />
							최첨단 기술과 예술적 감각을 융합하여 기술적으로 완벽하면서도 창의적으로 영감을 주는 시각적 콘텐츠를 제작합니다.
						</div>

						<hr className='opacity-30 my-4' />

						<div>
							We are a multifaceted creative studio that seamlessly blends technical expertise with artistic vision. Specializing in visual storytelling through photography, videography, and computer vision, we create content that captivates and inspires.
							<br />
							<br />
							With a deep mastery of lighting, camera technology, and post-production, we push creative boundaries to deliver polished, professional results. Our thoughtful approach to color, composition, and style ensures every project aligns with each client’s unique brand and vision.
							<br />
							<br />
							By fusing cutting-edge technology with artistic flair, we produce visuals that are both technically flawless and creatively inspiring. Whether it’s a photo series, a video production, or an innovative computer vision application, we bring visual concepts to life.
						</div>

						<hr className='opacity-30 my-4' />

						<div className={`opacity-70 text-xs flex flex-col gap-1`}>
							<span>Site created by UNDoX,</span>
							<span>Visuals 김수진 | Technicals 박준형 | Graphic Backgrounds 안서윤</span>
						</div>
					</div>

					<div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 gap-1'>
						<ContactCard
							link='https://www.instagram.com/and_unorthodox'
							target='_blank'
							thumbnail={`${process.env.PUBLIC_URL}/assets/system/sys_20.webp`}
							title='Instagram'
							subtitle='@and_unorthodox'
							internal={false}
							icon={
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
									<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
								</svg>
							}
						/>
						<ContactCard
							link='https://www.youtube.com/@and_unorthodox'
							target='_blank'
							thumbnail={`${process.env.PUBLIC_URL}/assets/system/sys_21.webp`}
							title='YouTube'
							subtitle='UNDoX'
							internal={false}
							icon={
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
									<path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
								</svg>
							}
						/>
						<ContactCard
							link='mailto:studio@and-undox.com'
							target={null}
							thumbnail={`${process.env.PUBLIC_URL}/assets/system/sys_29.webp`}
							title='Email'
							subtitle='studio@and-undox.com'
							internal={false}
							icon={
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
									<path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
								</svg>
							}
						/>
						<ContactCard
							link='https://open.kakao.com/me/and_unorthodox'
							target='_blank'
							thumbnail={`${process.env.PUBLIC_URL}/assets/system/sys_25.webp`}
							title='KakaoTalk'
							subtitle='언독스'
							internal={false}
							icon={
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-dots-fill" viewBox="0 0 16 16">
									<path d="M16 8c0 3.866-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
								</svg>
							}
						/>
					</div>

					<div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 gap-1'>
						{people.filter(person => person.title !== "Collaborator" & person.title !== "Model").map((person, index) => (
							<ContactCard
								key={index}
								link={person.links[0]}
								target="_blank"
								thumbnail={`https://and-undox.com/api/uploads/images/${person.image}`}
								title={person.name}
								subtitle={person.title}
								internal={false}
							/>
						))}
					</div>
				</div>
			</motion.div>
		</motion.div>
	);
};

export default About;
